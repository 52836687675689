import { winningCriteria, winningColor, splitColors, pdfSplitColors, splitLetters, resendType, mailingItemStatus } from '@/shared/models/mailing'

import { mailingService } from '@/shared/services'
import Vue from 'vue'

export const splitMixin = {
  computed: {
    $_splitMixin_winningCriteriaSelect () {
      return winningCriteria
    },
    $_splitMixin_splitColors () {
      return splitColors
    },
    $_splitMixin_pdfSplitColors () { // Mixin to handle pdf hex color codes for vue-html2pdf library
      return pdfSplitColors
    },
    $_splitMixin_splitLetters () {
      return splitLetters
    },
    $_splitMixin_winningColor () {
      return winningColor
    },
    $_splitMixin_resendTypeSelect () {
      return resendType
    },
    $_splitMixin_mailingItemStatusSelect () {
      return mailingItemStatus
    }
  },

  methods: {
    $_splitMixin_moveSplitColorToEnd (index) {
      splitColors.push(splitColors.splice(index, 1)[0])
    },
    $_splitMixin_indexToChar (index) {
      return this.$_splitMixin_splitLetters[index]
    },
    $_splitMixin_colorToTextClass (vuetifyColor) {
      var str = vuetifyColor.split(' ')
      if (str.length === 1) {
        return `${str[0]}--text`
      }
      return `${str[0]}--text text--${str[1]}`
    }
  }
}

export const splitMixinMailing = {

  mixin: [splitMixin],

  data () {
    return {
      splitMixin_storeReference: 'mailing',
      splitMixin_storeUnwatch: null,
      splitMixin_splits: [],
      splitMixin_expanded: []
    }
  },

  watch: {
    splitMixin_storeReference: {
      immediate: true,
      handler (newValue, oldValue) {
        if (this.splitMixin_storeUnwatch !== null) {
          this.splitMixin_storeUnwatch()
        }
        this.splitMixin_storeUnwatch = this.$store.watch(
          (state, getters) => getters[`${newValue}/getSplits`](),
          (newValue2, oldValue2) => {
            this.splitMixin_splits = JSON.parse(JSON.stringify(newValue2))
          }
        )
        this.splitMixin_splits = JSON.parse(JSON.stringify(this.$store.getters[`${newValue}/getSplits`]()))
      }
    }
  },

  computed: {
    $_splitMixin_isSplit () {
      return this.splitMixin_splits && this.splitMixin_splits.length > 1
    },
    $_splitMixin_isSplitTest () {
      return this.splitMixin_splits && this.splitMixin_splits.length > 1 && this.$_splitMixin_winningDistribution > 0
    },
    $_splitMixin_isTrueSplit () {
      return this.splitMixin_splits && this.splitMixin_splits.length > 1 && this.$_splitMixin_winningDistribution <= 0
    },
    $_splitMixin_isMaxSplits () {
      return (
        !this.splitMixin_splits ||
        this.splitMixin_splits.length === this.$_splitMixin_splitColors.length
      )
    },
    $_splitMixin_splitsDistribution () {
      if (!this.splitMixin_splits) return 0
      return this.splitMixin_splits.reduce(function (a, b) {
        return a + +b.distribution
      }, 0)
    },
    $_splitMixin_winningDistribution () {
      return !this.$_splitMixin_isSplit ? 0 : 100 - this.$_splitMixin_splitsDistribution
    }
  },

  methods: {
    $_splitMixin_setStoreReference (vuexModule) {
      this.splitMixin_storeReference = vuexModule
    },
    async $_splitMixin_loadSpamScores () {
      this.splitMixin_splits.forEach(async (split, index) => {
        Vue.set(split.spam, 'loading', true)
        this.$store.commit('setLoadingMessage', 'Loading Spam Scores')
        await mailingService.getSpamScore(split.id).then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            Vue.set(split, 'spam', resp)
            split.spam.scoreRounded = Math.round(split.spam.score * 2) / 2
          }
        }).finally(() => {
          Vue.set(split.spam, 'loading', false)
        })
      })
    },
    async  $_splitMixin_validateLinks () {
      this.splitMixin_splits.forEach(async (split, index) => {
        Vue.set(split.links, 'loading', true)
        await mailingService.checkLinks(split.id).then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.$_splitMixin_setLinks(index, resp)
          }
        }).finally(() => {
          Vue.set(split.links, 'loading', false)
        })
      })
    },
    $_splitMixin_setLinks (index, links) {
      Vue.set(this.splitMixin_splits[index], 'links', links)
      Vue.set(this.splitMixin_splits[index].links, 'items', links.items.map(x => {
        return {
          ...x,
          iconColor: x.isValid === false ? (x.statusCode === 410 ? 'error' : 'warning') : 'success',
          icon: x.isValid === false ? (x.statusCode === 410 ? 'mdi-alert-circle' : 'mdi-alert') : 'mdi-check'
        }
      }))
    },
    async $_splitMixin_getTextAnalysis (index) {
      if (!this.splitMixin_splits[index].id) {
        return
      }
      Vue.set(this.splitMixin_splits[index].textAnalysis, 'loading', true)
      await mailingService.splitTextAnalysis(this.splitMixin_splits[index].id, true).then(resp => {
        if (resp.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
        } else {
          Vue.set(this.splitMixin_splits[index], 'textAnalysis', resp)
          Vue.set(this.splitMixin_splits[index].textAnalysis, 'subjectSummary', this.$_splitMixin_overallSentiment(resp.subjectSentimentScores))
          Vue.set(this.splitMixin_splits[index].textAnalysis, 'htmlSummary', this.$_splitMixin_overallSentiment(resp.htmlSentiment.confidenceScores))
          Vue.set(this.splitMixin_splits[index].textAnalysis, 'reloaded', true)
        }
      }).finally(() => {
        Vue.set(this.splitMixin_splits[index].textAnalysis, 'loading', false)
      })
    },
    $_splitMixin_overallSentiment (scores) {
      if (!scores) {
        return { icon: 'mdi-emoticon-dead', color: 'grey', label: '', value: 0 }
      }
      if (scores.positive >= scores.neutral && scores.positive >= scores.negative) {
        return { icon: 'mdi-emoticon-happy', color: 'green', label: 'Positive', value: Math.round(scores.positive * 100) }
      }
      if (scores.neutral >= scores.positive && scores.neutral >= scores.negative) {
        return { icon: 'mdi-emoticon-neutral', color: 'light-blue', label: 'Neutral', value: Math.round(scores.neutral * 100) }
      }
      if (scores.negative >= scores.positive && scores.negative >= scores.neutral) {
        return { icon: 'mdi-emoticon-sad', color: 'red', label: 'Negative', value: Math.round(scores.negative * 100) }
      }
      return { icon: 'mdi-emoticon-dead', color: 'grey', label: '', value: 0 }
    }
  }

}
