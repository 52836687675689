import moment from 'moment'
import {
  purpleRedGradientDark16,
  greenGreyGradientLight8
} from '@/shared/constants'
import { taStatus, fileType, geoDataFileTypes } from '@/shared/models/noww'
import { campaignStatus, mailingItemStatus } from '@/shared/models/mailing'

import { splitMixin } from '@/shared/mixins/mailing'
import { enumsMixin } from '@/shared/mixins/general'
import { mailingService } from '@/shared/services'

export const dashboardMixin = {
  mixins: [splitMixin, enumsMixin],

  methods: {
    $_dashMixin_taStatusColor (status) {
      var match = taStatus.find(x => x.value === status)
      return match ? match.color : 'grey'
    },
    $_dashMixin_getTAIcon (status) {
      switch (status) {
        case 1:// draft
          return 'mdi-bullseye'
        case 2:// completed
          return 'mdi-bullseye-arrow'
        default:// not selected
          return 'mdi-checkbox-blank-circle-outline'
      }
    },
    $_dashMixin_getMailingIcon (mailing) {
      switch (mailing.status) {
        case 1:// draft
          if (mailing.discriminator === 'MailingItem') {
            return 'mdi-email'
          } else {
            return 'mdi-email-sync'
          }
        case 2:// tested
          return 'mdi-test-tube'
        case 3:// scheduled
          const scheduleDate = new Date(mailing.scheduleDateLocal);
          const currentDate = new Date();
          if (scheduleDate < currentDate) {
            return 'mdi-email-alert-outline' 
          } else {
            return 'mdi-send-clock'
          }
        case 4:// sent
          return 'mdi-send-check'
        case 5:// canceled
          return 'mdi-email-off-outline'
        case 100:// sending
          return 'mdi-email-fast'
      }
    },
    $_dashMixin_campaignStatusColor (campaign) {
      return campaignStatus.find(x => x.value === campaign.campaignStatus).color
    },
    $_dashMixin_getMailingIconColor(mailing) {
      const scheduleDate = new Date(mailing.scheduleDateLocal)
      const currentDate = new Date()
      if (scheduleDate < currentDate && mailing.status === 'Scheduled') {
        return 'error'
      }
      return mailingItemStatus.find(x => x.value === mailing.status).color;
    },
    $_dashMixin_countColor (count) {
      // for (let i = 0; i < purpleRedGradientDark16.length; i++) {
      //   if (
      //     count <= 1000 * Math.pow(2, i) ||
      //     i === purpleRedGradientDark16.length - 1
      //   ) {
      //     return purpleRedGradientDark16[i].color
      //   }
      // }
      for (let i = 1, sendThreshold = 10000; i <= 7; i++, sendThreshold += 15000) {
        if (count <= sendThreshold || i === 7) {
          return purpleRedGradientDark16[i * 2].color
        }
      }
      return purpleRedGradientDark16[0].color
    },
    $_dashMixin_countSendDays (count) {
      for (let i = 1, sendThreshold = 10000; i <= 7; i++, sendThreshold += 15000) {
        if (count <= sendThreshold || i === 7) {
          return i
        }
      }
      return 1
    },
    $_dashMixin_countSendDaysLabel (count) {
      let labelObj = this.$_dashMixin_countSendDaysShortLabel(count)
      return `This Target Audience will be sent spanning across ${labelObj.value} ${labelObj.label} for each mailing (may be shorter for split sends).`
    },
    $_dashMixin_countSendDaysShortLabel (count) {
      let days = this.$_dashMixin_countSendDays(count)
      if (days <= 3) {
        return {
          value: days * 24,
          label: 'hours'
        }
      } else {
        return {
          value: days,
          label: 'days'
        }
      }
    },
    $_dashMixin_fileTypeColor (fileTypeName) {
      var ft = fileType.find(x => x.name === fileTypeName)
      if (ft) {
        return ft.color
      }
      return '#fffff'
    },
    $_dashMixin_fileTypeColorDark (fileTypeName) {
      var ft = fileType.find(x => x.name === fileTypeName)
      if (ft) {
        return ft.dark
      }
      return false
    },
    $_dashMixin_isGeoDataFileTypeId (fileTypeId) {
      return geoDataFileTypes.filter(x => x.id === fileTypeId).length > 0
    },
    $_dashMixin_pastDateColor (date) {
      if (!date) return 'grey'
      let dateMoment = moment(date).format()
      let eod = moment()
        .endOf('day')
        .format()
      const stepper = [
        { val: 3, period: 'd' },
        { val: 1, period: 'w' },
        { val: 2, period: 'w' },
        { val: 3, period: 'w' },
        { val: 1, period: 'M' },
        { val: 2, period: 'M' },
        { val: 4, period: 'M' }
      ]
      if (dateMoment > eod) { return 'purple lighten-3' }
      for (let i = 0; i < greenGreyGradientLight8.length; i++) {
        if (i === greenGreyGradientLight8.length - 1) { return greenGreyGradientLight8[i].color }
        if (
          dateMoment >
          moment()
            .endOf('day')
            .subtract(stepper[i].val, stepper[i].period)
            .format()
        ) { return greenGreyGradientLight8[i].color }
      }
    },
    $_dashMixin_isInPast (date) {
      return date < moment().format()
    },
    $_dashMixin_isInPastBufferMinutes (date, mins) {
      return date < moment().add(mins, 'm').format()
    },
    $_dashMixin_scheduleDateColor (date, mailing, campaign) {
      var bufferMins = -10
      // if (campaign.isCanceled && !date) return 'error'
      if (!date || !mailing.isScheduled) return 'grey'
      let dateMoment = moment(date).format()
      if (campaign && campaign.isCanceled && (!this.$_dashMixin_isInPastBufferMinutes(dateMoment, bufferMins) && mailing.isScheduled)) return 'error'
      if (mailing.status === this.$_mailingItemStatus_Sending) return 'green'
      if (mailing.status === this.$_mailingItemStatus_Sent || mailing.allSplitsBroadcasted) return 'success'
      if (this.$_dashMixin_isInPastBufferMinutes(dateMoment, bufferMins) && !mailing.isProcessing) return 'error'
      else return 'accent'
    },
    $_dashMixin_scheduleDateColorWinner (date, mailing, campaign) {
      var bufferMins = -10
      // if (campaign.isCanceled && !date) return 'error'
      if (!date || !mailing.isScheduled) return 'grey'
      let dateMoment = moment(date).format()
      if (campaign && campaign.isCanceled && (!this.$_dashMixin_isInPastBufferMinutes(dateMoment, bufferMins) && mailing.isScheduled)) return 'error'
      if (mailing.status === this.$_mailingItemStatus_Sending) return 'green'
      if (mailing.status === this.$_mailingItemStatus_Sent) return 'success'
      if (this.$_dashMixin_isInPastBufferMinutes(dateMoment, bufferMins) && !mailing.isProcessing) return 'error'
      else return 'accent'
    },
    $_dashMixin_dateToLocal (utc) {
      if (!utc) return null
      return moment(this.$_global_parseAsUtc(utc)).local().format()
    },
    $_dashMixin_nowwEditLink (item/*, _blank = false */) {
      /* if (_blank) {
        let route = {}
        if (item.fileType === 'CSTM') {
          route = this.$router.resolve({
            name: 'TargetAudience',
            params: {
              targetAudienceId: item.id
            }
          })
        } else {
          route = this.$router.resolve({
            name: 'NowwRedirect',
            params: {
              nowwRoute: `count-start-submit.aspx?jobnumber=${item.jobNumber}&FileTypeID=${item.fileTypeId}&MainScreenGroupID=1`
            }
          })
        }
        window.open(route.href, '_blank')
      } else { */
      if (item.fileType === 'CSTM') {
        this.$router.push({
          name: 'TargetAudience',
          params: {
            targetAudienceId: item.id
          }
        })
      } else {
        this.$router.push({
          name: 'NowwRedirect',
          params: {
            nowwRoute: `count-start-submit.aspx?jobnumber=${item.jobNumber}&FileTypeID=${item.fileTypeId}&MainScreenGroupID=1`
          }
        })
      }
      // }
    },
    $_dashMixin_nowwCopyLink (item) {
      if (item.fileType === 'CSTM') {
        mailingService.copyTA(item.id)
          .then(resp => {
            if (resp.errorResponse) {
              this.$store.commit('setGlobalSnackbar', {
                message: resp.errorResponse,
                color: 'error'
              })
            } else {
              this.$store.commit('setGlobalSnackbar', {
                message: `Target Audience ${resp.jobNumber} has been created!`,
                color: 'success',
                isPageChange: true
              })
              if (this.$route.name === 'TADashboard') {
                this.$emit('reload')
              } else {
                this.$router.push({
                  name: 'TargetAudience',
                  params: {
                    targetAudienceId: resp.id
                  }
                })
              }
            }
          })
      } else {
        let nowwLink = this.$store.getters['auth/isMMSRole']()
          ? `copyorderfromdashboard.aspx?jobnumber=${item.jobNumber}&FileTypeID=${item.fileTypeId}&ContactID=${item.nowwContactId}&CustomerID=${item.nowwCustomerId}&MainScreenGroupID=1`
          : `copyorder.aspx?jobnumber=${item.jobNumber}&FileTypeID=${item.fileTypeId}&MainScreenGroupID=1`
        this.$router.push({
          name: 'NowwRedirect',
          params: { nowwRoute: nowwLink }
        })
      }
    },
    $_dashMixin_deleteTA (item) {
      mailingService.deleteTA(item.id)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.$store.commit('setGlobalSnackbar', {
              message: `Target Audience ${item.jobNumber} has been deleted!`,
              color: 'success',
              isPageChange: true
            })
            if (this.$route.name === 'TADashboard') {
              this.$emit('reload')
            } else {
              this.$router.push({ name: 'TADashboard' })
            }
          }
        })
    },
    async $_dashMixin_addMailing (campaign) {
      this.$store.commit('setLoadingMessage', 'Adding Mailing')
      await mailingService
        .addMailing(campaign.campaignId)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.$router.push({ name: 'MailingCampaign', params: { mailingItemId: resp } })
          }
        })
    },
    async $_dashMixin_cancelCampaign (campaign) {
      await mailingService.cancelCampaign(campaign.campaignId)
      this.$store.commit('setGlobalSnackbar', {
        message: `${campaign.campaignName} has been canceled!`,
        color: 'success',
        isPageChange: true
      })
      if (this.$store.getters['mailing/getCampaignId']() === campaign.campaignId) {
        this.$router.push({ name: 'CampaignDashboard' })
      } else {
        campaign.isCanceled = true
        return campaign
      }
    },
    $_dashMixin_continueMailing (mailing) {
      this.$router.push({ name: 'MailingCampaign', params: { mailingItemId: mailing.id } })
    },
    async $_dashMixin_copyMailing (mailing) {
      this.$store.commit('setLoadingMessage', 'Copying Mailing')
      await mailingService
        .copyMailing(mailing.id)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.$router.push({ name: 'MailingCampaign', params: { mailingItemId: resp } })
          }
        })
    },
    async $_dashMixin_copyCampaign (campaign) {
      this.$store.commit('setLoadingMessage', 'Copying Campaign')
      await mailingService
        .copyCampaign(campaign.campaignId)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.$store.commit('setGlobalSnackbar', {
              message: `${campaign.campaignName} copied successfully!`,
              color: 'success'
            })
            if (this.$store.getters['mailing/getCampaignId']() === campaign.campaignId) {
              this.$router.push({ name: 'CampaignDashboard' })
            } else {
              this.$emit('reload')
            }
          }
        })
    },
    async $_dashMixin_unscheduleMailing (mailing) {
      this.$store.commit('setLoadingMessage', 'Unscheduling Mailing')
      await mailingService
        .setMailingValue(mailing.id, 'scheduledOn', null)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.$store.commit('setGlobalSnackbar', {
              message: `${mailing.name} unscheduled successfully!`,
              color: 'success'
            })
            if (this.$store.getters['mailing/getMailingItemId']() === mailing.id) {
              this.$router.go()
            } else {
              this.$router.push({ name: 'MailingCampaign', params: { mailingItemId: mailing.id } })
            }
          }
        })
    },
    async $_dashMixin_deleteResend (campaign, resend, index) {
      this.$store.commit('setLoadingMessage', 'Deleting Resend')
      await mailingService
        .deleteResend(resend.id)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.$store.commit('setGlobalSnackbar', {
              message: `${resend.name} deleted successfully!`,
              color: 'success'
            })
            if (this.$store.getters['mailing/getMailingItemId']() === resend.id) {
              this.$router.push({ name: 'CampaignDashboard' })
            } else {
              campaign.mailings.splice(index, 1)
              return campaign
            }
          }
        })
    },
    $_dashMixin_campaignHistory (campaignId) {
      this.$router.push({ name: 'CampaignHistory', params: { campaignId: campaignId } })
    }
  }
}
