export const dragAndDropMixin = {
  data () {
    return {
      dragAndDropMixin_style: {
        title: 'dragAndDropCSS',
        style: `<style type="text/css" title="dragAndDropCSS">
        body {
            width: 100%; margin: 0; padding: 0; background-color: #f6f7fb; font-family: Arial, sans-serif; mso-line-height-rule: exactly; -webkit-font-smoothing: antialiased; -ms-text-size-adjust: none !important;
        }
        
        @media only screen and (max-width:600px) {
            .button-border { display: block !important; }
        }
        
        .content table,
        .header table,
        .footer table,
        .content,
        .footer,
        .header {
            width: 100% !important; max-width: 600px !important;
        }
        
        .adapt-img {
            width: 100% !important; height: auto !important;
        }
    
        .button {
            mso-style-priority: 100 !important; text-decoration: none !important;
        }
        @media only screen and (max-width:640px) {
            .deviceWidth {
                width: 100% !important; padding: 0;
            }
            
            .center {
                text-align: center !important;
            }
        }
        @media only screen and (max-width: 479px) {
            .deviceWidth { width: 100% !important; padding: 0; }
        }
        .center {
            text-align: center !important;
        }
    
    </style>`
      },
      dragAndDropMixin_items: [
        {
          group: 'sections',
          id: 'drag-1-column',
          icon: 'mdi-square',
          title: '1 Column',
          content: `<table style="width: 100%; min-height: 70px; margin: 1em auto; border-collapse: collapse;">
            <tbody>
              <tr>
                <td style="width: 100.0000%;">
                  <br>
                </td>
              </tr>
            </tbody>
          </table>`
        },
        {
          group: 'sections',
          id: 'drag-2-column',
          icon: 'mdi-pause',
          title: '2 Column',
          content: `<table style="width: 100%; min-height: 70px; margin: 1em auto; border-collapse: collapse;">
            <tbody>
              <tr>
                <td style="width: 50.0000%;">
                  <br>
                </td>
                <td style="width: 50.0000%;">
                  <br>
                </td>
              </tr>
            </tbody>
          </table>`
        },
        {
          group: 'sections',
          id: 'drag-3-column',
          icon: 'mdi-view-column',
          title: '3 Column',
          content: `<table style="width: 100%; min-height: 70px; margin: 1em auto; border-collapse: collapse;">
            <tbody>
              <tr>
                <td style="width: 33.3333%;">
                  <br>
                </td>
                <td style="width: 33.3333%;">
                  <br>
                </td>
                <td style="width: 33.3333%;">
                  <br>
                </td>
              </tr>
            </tbody>
          </table>`
        },
        {
          group: 'sections',
          id: 'drag-2-1',
          icon: 'mdi-square',
          title: '2-1',
          content: `<table style="width: 100%; min-height: 70px; margin: 1em auto; border-collapse: collapse;">
            <tbody>
              <tr>
                <td style="width: 66.6667%;">
                  <br>
                </td>
                <td style="width: 33.3333%;">
                  <br>
                </td>
              </tr>
            </tbody>
          </table>`
        },
        {
          group: 'sections',
          id: 'drag-1-2',
          icon: 'mdi-square',
          title: '1-2',
          content: `<table style="width: 100%; min-height: 70px; margin: 1em auto; border-collapse: collapse;">
            <tbody>
              <tr>
                <td style="width: 33.3333%;">
                  <br>
                </td>
                <td style="width: 66.6667%;">
                  <br>
                </td>
              </tr>
            </tbody>
          </table>`
        },
        {
          group: 'basic',
          id: 'drag-btn',
          icon: 'mdi-gesture-tap-button',
          title: 'Button',
          content: `<table cellpadding="0" cellspacing="0" border="0" style="width: 100%; border-collapse: collapse;">
          <tbody>
              <tr>
               <td align="center" valign="top" style="padding:25px 0;"><table cellpadding="0" cellspacing="0" border="0" width="167" align="center" style="width:167px;">
                <tr>
            <td height="30" align="center" valign="middle" style="text-align: center; color: #ffffff; border-radius: 4px; background-color: rgb(34, 102, 227);">
             <a class="button" href="#" target="_blank" style="display: block; color: #ffffff; text-decoration: none; line-height:30px;">Button</a>
            </td>
            </tr>
               </table>
               </td>
              </tr>
            
          </tbody>
          </table>`
        },
        {
          group: 'basic',
          id: 'drag-divider',
          icon: 'mdi-minus',
          title: 'Divider',
          content: '<hr>'
        },
        {
          group: 'content',
          id: 'drag-header',
          icon: 'mdi-page-layout-header',
          title: 'Header',
          content: `<table cellpadding="0" class="header" align="center" cellspacing="0" width="100%" bgcolor="white" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:white;max-width:600px;"> 
            <tbody>
              <tr style="border-collapse:collapse;"> 
                <td align="center" style="Margin:0;padding-bottom:20px;padding-left:20px;padding-right:30px;padding-top:20px;">
                  <a target="_blank" href="http://www.example.com" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-size:15px;text-decoration:none;color:black;">
                    <img src="https://via.placeholder.com/160/21" alt="logo" style="display: block; border: 0px; outline: none; text-decoration: none; width: 212px;" width="160" class="fr-fic fr-dii"/>
                  </a>
                </td> 
              </tr> 
              <tr style="border-collapse:collapse;"> 
                <td align="center" bgcolor="white" style="Margin:0;padding-top:15px;padding-left:15px;padding-right:15px;padding-bottom:25px;">
                  <h2 style="Margin:0;line-height:30px;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-size:15px;font-style:normal;font-weight:normal;color:#000000;text-align:center;">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </h2>
                </td> 
              </tr> 
              <tr style="border-collapse:collapse;"> 
                <td align="center" bgcolor="#59afe1" style="Margin:0;padding-bottom:20px;padding-top:25px;padding-left:40px;padding-right:40px;font-family:arial, 'helvetica neue', helvetica, sans-serif;">
                  <span style="color:#FFFFFF;">
                    <strong>
                      <span style="font-size:28px;">
                        Lorem Ipsum
                      </span>
                    </strong>
                  </span>
                </td> 
              </tr>
            </tbody>
          </table>`
        },
        {
          group: 'content',
          id: 'drag-story',
          icon: 'mdi-page-layout-body',
          title: 'Story',
          content: `<table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="content" style="border-spacing:0;border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;table-layout:fixed !important;width:100%;">
            <tbody>
              <tr style="border-collapse:collapse;">
                <td width="100%" valign="top"  style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;">
                  <table border="0" cellspacing="0" cellpadding="0" align="center" class="deviceWidth" style="width:600px;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;border-spacing:0;border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;">
                    <tbody>
                      <tr style="border-collapse:collapse;">
                        <td align="center" bgcolor="#ffffff" style="border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;">
                          <a target="_blank" href="http://www.example.com" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-size:15px;text-decoration:none;color:#FFFFFF;">
                            <img class="adapt-img fr-fic fr-dii" src="https://via.placeholder.com/600x400?text=600+x+400" alt="" style="display:block;"/>
                          </a>
                          <table border="0" cellpadding="0" cellspacing="0" align="center" class="deviceWidth" style="width:600px;border-spacing:0;border-collapse:collapse;mso-table-lspace:0;mso-table-rspace:0;">
                            <tbody>
                              <tr style="border-collapse:collapse;">
                                <td align="center" bgcolor="#59afe1" style="Margin:0;padding-bottom:20px;padding-top:25px;padding-left:40px;padding-right:40px;">
                                  <a target="_blank" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-size:28px;text-decoration:none;color:#FFFFFF;" href="http://www.example.com">
                                    Lorem Ipsum Dolor Sit Amet
                                  </a>
                                </td> 
                              </tr> 
                              <tr style="border-collapse:collapse;"> 
                                <td align="center" style="Margin:0;padding-bottom:10px;padding-top:20px;padding-left:40px;padding-right:40px;">
                                  <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-size:15px;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:23px;color:#333333;">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur urna lectus, viverra ut sagittis quis, vulputate venenatis quam. Aliquam erat volutpat.
                                  </p>
                                </td> 
                              </tr>
                              <tr style="border-collapse:collapse;">
                                <td align="center"  style="Margin:0;padding-top:10px;padding-left:10px;padding-right:10px;padding-bottom:40px;">
                                  <a href="http://www.example.com" target="_blank" style="text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-size:18px;color:#FFFFFF;border-style:solid;border-color:black;border-width:10px 20px 10px 20px;display:inline-block;background:black;border-radius:0px;font-weight:normal;font-style:normal;line-height:22px;text-align:center;">
                                    LEARN MORE
                                  </a>
                                </td> 
                              </tr> 
                            </tbody>
                          </table> 
                        </td> 
                      </tr>
                    </tbody>
                  </table> 
                </td>
              </tr>
            </tbody> 
          </table>`
        },
        {
          group: 'content',
          id: 'drag-footer',
          icon: 'mdi-page-layout-footer',
          title: 'Footer',
          content: `<table align="center" class="footer" border="0" bgcolor="#999999" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
            <tbody>
              <tr>
                <td height="40">&nbsp;</td>
              </tr>
              <tr>
                <td align="center">
                  <table align="center" border="0" cellpadding="0" cellspacing="0" width="171">
                    <tbody>
                      <tr>
                        <td align="center" width="26"><a href="http://www.example.com" target="_blank"><img alt="facebook" border="0"  height="26"  src="https://via.placeholder.com/26" style="display:block;" width="26"/></a></td>
                        <td width="10">&nbsp;</td>
                        <td align="center" width="26"><a href="http://www.example.com" target="_blank"><img alt="Linked In" border="0"  height="26"  src="https://via.placeholder.com/26" style="display:block;" width="26"/></a></td>
                        <td width="10">&nbsp;</td>
                        <td align="center" width="26"><a href="http://www.example.com" target="_blank"><img alt="twitter" border="0"  height="26"  src="https://via.placeholder.com/26" style="display:block;" width="26"/></a></td>
                        <td width="10">&nbsp;</td>
                        <td align="center" width="26"><a href="http://www.example.com" target="_blank"><img alt="you_tube" border="0"  height="26"  src="https://via.placeholder.com/26" style="display:block;" width="26"/></a></td>
                        <td width="10">&nbsp;</td>
                        <td align="center" width="26"><a href="http://www.example.com" target="_blank"><img alt="google_plus" border="0"  height="26"  src="https://via.placeholder.com/26" style="display:block;" width="26"/></a></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height="20">&nbsp;</td>
              </tr>
              <tr>
                <td align="center" style="font-family:Tahoma, Geneva, sans-serif; font-size:11px; line-height:13px; color:#ffffff;">&copy; 2021. Site your company</td>
              </tr>
              <tr>
                <td height="20">&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tbody>
                      <tr>
                        <td width="60">&nbsp;</td>
                        <td align="center" style="font-family:Tahoma, Geneva, sans-serif; font-size:11px; line-height:18px; color:#ffffff;">123, Street Name, Address Line 2, Country<br />
                          <a href="mailto:info@yourcompany.com" style="text-decoration:none; color:#ffffff;">info@yourcompany.com</a><br />
                          <br />
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed nunc eget urna tristique gravida in et est. Fusce dictum sem at condimentum porttitor. Integer varius condimentum enim sed pharetra.<br />
                          <br />
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed nunc eget urna tristique gravida in et est.
                        </td>
                        <td width="60">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height="40">&nbsp;</td>
              </tr>
            </tbody>
          </table>`
        },
        {
          group: 'content',
          id: 'drag-left-image-promo',
          icon: 'mdi-page-layout-sidebar-left',
          title: 'Left',
          title2: 'Image',
          content: `<table align="center" bgcolor="#59afe1" border="0" cellpadding="0" cellspacing="0" class="deviceWidth" style="max-width: 600px;" width="100%">
            <tbody>
              <tr>
                <td style="padding: 20px 0;">
                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tbody>
                      <tr>
                        <td width="35">&nbsp;</td>
                        <td>
                          <table align="left" border="0" cellpadding="0" cellspacing="0" class="deviceWidth" width="49%">
                            <tbody>
                              <tr>
                                <td align="left" class="center"><img alt="Image" border="0" class="deviceWidth adapt-img" src="https://via.placeholder.com/260/200" style="width: 236px; vertical-align: top; margin: 5px auto; text-align: center; display: block;" width="250" /></td>
                              </tr>
                              <tr>
                                <td height="6" style="line-height:15px; font-size:6px;">&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                          <br>
                          <table align="center" border="0" cellpadding="0" cellspacing="0" class="deviceWidth" width="49%">
                            <tbody>
                              <tr>
                                <td align="left" style="font-family: Arial, Helvetica, sans-serif; font-size: 20px; line-height: 22px; color: rgb(255, 255, 255); font-weight: bold;">Lorem ipsum dolor sit amet</td>
                              </tr>
                              <tr>
                                <td height="20">&nbsp;</td>
                              </tr>
                              <tr>
                                <td align="left" style="font-family:Tahoma, Geneva, sans-serif; font-size:13px; line-height:20px; color:#ffffff;">
                                  <ul>
                                    <li>Lorem ipsum dolor amet consectetur</li>
                                    <li>adipiscing elit Nunc in tincidunt urn</li>
                                    <li>laoreet lorem Sed vitae ornare augu</li>
                                    <li>Praesent vestibulum in felis molestie</li>
                                  </ul>
                                </td>
                              </tr>
                              <tr>
                                <td height="15" style="line-height:15px; font-size:15px;">&nbsp;</td>
                              </tr>
                              <tr>
                                <td>
                                  <table align="left" border="0" cellpadding="0" cellspacing="0" style="border-radius:2px; background-color:#000916;" width="156">
                                    <tbody>
                                      <tr>
                                        <td align="center" height="38" style="font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#ffffff;"><a href="http://www.example.com" style="text-decoration:none; color:#ffffff; display:block; line-height:38px;" target="_blank">Read More</a></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width="35">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>`
        },
        {
          group: 'content',
          id: 'drag-right-image-promo',
          icon: 'mdi-page-layout-sidebar-right',
          title: 'Right',
          title2: 'Image',
          content: `<table align="center" bgcolor="#59afe1" border="0" cellpadding="0" cellspacing="0" class="deviceWidth" style="max-width: 600px;" width="100%">
            <tbody>
              <tr>
                <td style="padding: 20px 0;">
                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tbody>
                      <tr>
                        <td width="35">&nbsp;</td>
                        <td>
                          <table align="right" border="0" cellpadding="0" cellspacing="0" class="deviceWidth" width="49%">
                            <tbody>
                              <tr>
                                <td align="left" class="center"><img alt="Image" border="0" class="deviceWidth adapt-img" src="https://via.placeholder.com/260/200" style="width: 250px; vertical-align: top; margin: 5px auto; text-align: center; display: block;" width="250" /></td>
                              </tr>
                              <tr>
                                <td height="6" style="line-height:15px; font-size:6px;">&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                          <br>
                          <table align="center" border="0" cellpadding="0" cellspacing="0" class="deviceWidth" width="49%">
                            <tbody>
                              <tr>
                                <td align="left" style="font-family: Arial, Helvetica, sans-serif; font-size: 20px; line-height: 22px; color: rgb(255, 255, 255); font-weight: bold;">Lorem ipsum dolor sit amet</td>
                              </tr>
                              <tr>
                                <td height="20">&nbsp;</td>
                              </tr>
                              <tr>
                                <td align="left" style="font-family:Tahoma, Geneva, sans-serif; font-size:13px; line-height:20px; color:#ffffff;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed nunc eget urna tristique gravida in et est. Fusce dictum sem at condimentum porttitor. Integer varius condimentum enim sed pharetra.</td>
                              </tr>
                              <tr>
                                <td height="15" style="line-height:15px; font-size:15px;">&nbsp;</td>
                              </tr>
                              <tr>
                                <td>
                                  <table align="left" border="0" cellpadding="0" cellspacing="0" style="border-radius:2px; background-color:#000916;" width="156">
                                    <tbody>
                                      <tr>
                                        <td align="center" height="38" style="font-family:Arial, Helvetica, sans-serif; font-size:12px; color:#ffffff;"><a href="http://www.example.com" style="text-decoration:none; color:#ffffff; display:block; line-height:38px;" target="_blank">Read More</a></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width="35">&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>`
        }
      ]
    }
  }
}
